// import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function () {
    $('.homeHeaderCarousel').slick({
        infinite: true,
        arrows: true,
        dots: true,
        fade: true,
        autoplay: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    autoplay: true
                }
            }
        ]
    });
    $('.homeHeaderCarousel').on('afterChange', function () {
        var dataId =  parseInt($('.slick-current').attr('data-slick-index')) + 1;
        $('.homeHeaderCarouselLogo').each(function () {
            $(this).removeClass('active');
            if ($(this).data('slide') === dataId) {
                $(this).addClass('active');
            }
        });
    });
    $('.homeHeaderCarouselLogo').click(function (e) {
        e.preventDefault();
        let slideno = $(this).data('slide');
        $('.homeHeaderCarousel').slick('slickGoTo', slideno - 1);
    });
});
